<template>
  <section>
      <div class="content-header">
        <h2>Works cited</h2>
      </div>
      <div class="content-wrapper">
        <p>Here are the resources cited throughout this section of the toolkit, in the order in which they appeared.  </p>
        <ul>
          <li class="pb-3">University of British Columbia. (2020). <em>Fostering academic tenacity: Promoting participation</em>. WellBeing.ubc.ca. <a href="https://wellbeing.ubc.ca/sites/wellbeing.ubc.ca/files/u9/2020-04-01%20%20Participation.pdf" target="_blank">wellbeing.ubc.ca/sites/wellbeing.ubc.ca/files/u9/2020-04-01%20%20Participation.pdf</a>
</li>
          <li class="pb-3">UCLA Health. (n.d.). <em>Breathing Meditation</em> [Audio]. <a href="https://www.uclahealth.org/marc/mpeg/01_Breathing_Meditation.mp3" target="_blank">www.uclahealth.org/marc/mpeg/01_Breathing_Meditation.mp3</a>
</li>
          <li class="pb-3">Fenesi, B., Lucibello, K., Kim, J. A., & Heisz, J. J. (2018). Sweat so you don’t forget: Exercise breaks during a university lecture increase on-task attention and learning. Journal of Applied Research in Memory and Cognition, 7(2), 261-269. <a href="https://www.sciencedirect.com/science/article/abs/pii/S2211368116301929?via%3Dihub" target="_blank">doi.org/10.1016/j.jarmac.2018.01.012</a> </li>
          <li class="pb-3">Oppezzo, M., & Schwartz, D. L. (2014). Give your ideas some legs: The positive effect of walking on creative thinking. Journal of Experimental Psychology: Learning, Memory, and Cognition, 40(4), 1142-1152. <a href="https://doi.apa.org/doiLanding?doi=10.1037%2Fa0036577" target="_blank">dx.doi.org/10.1037/a0036577</a></li>
          <li class="pb-3">X University Movement Breaks <a href="https://www.ryerson.ca/recreation/programs/movement-breaks/ " target="_blank">www.ryerson.ca/recreation/programs/movement-breaks </a></li>
          <li class="pb-3">TEDx Talks. (2019, April 3). A key ingredient in the student resilience recipe | Joanne Dominico | TEDxCentennialCollegeToronto [Video]. YouTube. <a href="https://www.youtube.com/watch?v=RnFt6HKrSmo" target="_blank">www.youtube.com/watch?v=RnFt6HKrSmo</a></li>
          <li class="pb-3">Don’t Call Me Resilient, Vinita Srivastava: <a href="https://dont-call-me-resilient.simplecast.com/" target="_blank">dont-call-me-resilient.simplecast.com/</a></li>
          <li class="pb-3">Resilience and Resistance Podcast, Marisol Solarte-Eriacher: <a href="https://art19.com/shows/resilience-and-resistance " target="_blank">art19.com/shows/resilience-and-resistance </a></li>
          <li class="pb-3">Resilient People, Janet Fanaki:  <a href="https://resilient-people.simplecast.com" target="_blank">resilient-people.simplecast.com</a></li>
            <li>Canada Life. (2019). <em>From Surviving to Thriving</em>. Workplace Strategies for Mental Health. <a href="https://www.workplacestrategiesformentalhealth.com/resources/post-secondary-student-resilience" target="_blank">https://www.workplacestrategiesformentalhealth.com/resources/post-secondary-student-resilience</a> </li>
        </ul>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '05',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
